/*

https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#adding-a-css-preprocessor-sass-less-etc

 */

body {
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: 0!important;
}

html,
body {
  margin: 0;
  padding: 0;
}

/* Gestion du style de la 1er div de react présente dans src/Start.js */
#reactroot-element {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}
// https://stackoverflow.com/a/248013/2633092
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

#app {
  margin: 0 !important;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

img {
  /* https://stackoverflow.com/a/42216003/2633092 */
  image-rendering: -webkit-optimize-contrast;

  /* https://stackoverflow.com/a/36787557/2633092 */
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0); /* IE 9 */
  -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
  transform: translateZ(0);
}

#app #menu {
  background: transparent;
}

#home {
  margin: 0 !important;
}

#bg {
  position: fixed;
  top: -35%;
  left: 10%;
  width: 100%;
  height: 100%;
  z-index: -100;
}

#bg img {
  position: absolute;
  top: 40px;
  left: -50%;
  right: -50%;
  bottom: 0;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
}

#footer {
  margin: 0px !important;
}

#footer .column {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

#footer .horizontal .item {
  line-height: 1;
}

.rangeslider-horizontal .rangeslider__tooltip {
  top: -55px;
  left: -7px;
}

#html.ace_editor {
  width: 100% !important;
}

.joyride {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
}

pre {
  margin: 0 !important;
}

.pattern-background {
  background-image: url(https://res.cloudinary.com/lifebot/image/upload/inspiration-geometry.png) !important;
}

.m-no-all {
  margin: 0 !important;
}

.m-no-bottom {
  margin-bottom: 0 !important;
}

.m-no-top {
  margin-top: 0 !important;
}

.m-no-left {
  margin-left: 0 !important;
}

.m-no-right {
  margin-right: 0 !important;
}

.p-no-all {
  padding: 0 !important;
}

.p-no-bottom {
  padding-bottom: 0 !important;
}

.p-no-top {
  padding-top: 0 !important;
}

.p-no-left {
  padding-left: 0 !important;
}

.p-no-right {
  padding-right: 0 !important;
}

.m-bottom-10 {
  margin-bottom: 10px !important;
}

.m-bottom-20 {
  margin-bottom: 20px !important;
}

.m-bottom-30 {
  margin-bottom: 30px !important;
}

.m-top-10 {
  margin-top: 10px !important;
}

.m-top-30 {
  margin-top: 30px !important;
}

.background-blue {
  background-image: linear-gradient(150deg, #4553ff 35%, #05d5ff 100%);
}

.border-green {
  border: 2px solid #01c3a7 !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.65);
}

.left-text {
  text-align: left !important;
}

.center-text {
  text-align: center !important;
}

.justify-text {
  text-align: justify !important;
}

.center-block {
  margin: auto !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

b.green {
  color: #01c3a7;
}

sup.green {
  color: #01c3a7;
}

.pricing > .item > .header {
  font-size: 24px;
  font-weight: 300 !important;
  line-height: 26px;
}

.pricing > .item > p {
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 26px;
}

.pricing > .item:not(.last) {
  margin-bottom: 20px;
}

.pricing-value {
  border: 1px dotted #cccccc;
  background: rgba(255, 255, 255, 0.8);
}

.pricing-value > .statistic {
  margin: 10px 10px !important;
}

/*
 Sidebar TOP
 */
/* Fix a bug preventing react-scroll to work */
.pushable {
  overflow-x: visible !important;
}

.visible.sidebar ~ .pusher {
  -webkit-transform: translate3d(0, 47px, 0) !important;
  transform: translate3d(0, 47px, 0) !important;
}

/* Fix size for pusher for message on 2 lines */
@media only screen and (max-width: 620px) {
  .visible.sidebar ~ .pusher {
    -webkit-transform: translate3d(0, 76px, 0) !important;
    transform: translate3d(0, 76px, 0) !important;
  }
}

/**
Fix react-month-pick
 */

@media screen and (max-width: 767px) {
  .month-picker > .container .popup {
    top: 340px;
  }
}

/*
 Semantic UI has these classes, however they're only applicable to
 grids, containers, rows and columns.
 plus, there isn't any `mobile hidden`, `X hidden` class.
 this snippet is using the same class names and same approach
 plus a bit more but to all elements.

 see https://github.com/Semantic-Org/Semantic-UI/issues/1114
*/

/* Mobile */
@media only screen and (max-width: 767px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large screen only']:not(.mobile),
  [class*='widescreen only']:not(.mobile),
  [class*='or lower hidden'] {
    display: none !important;
  }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*='mobile only']:not(.tablet),
  [class*='tablet hidden'],
  [class*='computer only']:not(.tablet),
  [class*='large screen only']:not(.tablet),
  [class*='widescreen only']:not(.tablet),
  [class*='or lower hidden']:not(.mobile) {
    display: none !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*='mobile only']:not(.computer),
  [class*='tablet only']:not(.computer),
  [class*='computer hidden'],
  [class*='large screen only']:not(.computer),
  [class*='widescreen only']:not(.computer),
  [class*='or lower hidden']:not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*='mobile only']:not([class*='large screen']),
  [class*='tablet only']:not([class*='large screen']),
  [class*='computer only']:not([class*='large screen']),
  [class*='large screen hidden'],
  [class*='widescreen only']:not([class*='large screen']),
  [class*='or lower hidden']:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*='mobile only']:not([class*='widescreen']),
  [class*='tablet only']:not([class*='widescreen']),
  [class*='computer only']:not([class*='widescreen']),
  [class*='large screen only']:not([class*='widescreen']),
  [class*='widescreen hidden'],
  [class*='widescreen or lower hidden'] {
    display: none !important;
  }
}

/**
* Rangeslider
*/
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle:hover .rangeslider__tooltip {
  opacity: 1;
}

.rangeslider .rangeslider__tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  opacity: 0;
}

.rangeslider .rangeslider__tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}

.rangeslider .rangeslider__tooltip:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
}

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #21314d !important;
  border-radius: 10px;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: -10px;
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}

.rangeslider-horizontal .rangeslider__tooltip {
  top: -55px;
}

.rangeslider-horizontal .rangeslider__tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 12px;
  bottom: -8px;
}

/**
* Rangeslider - Vertical slider
*/
.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}

.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #01c3a7 !important;
  box-shadow: none;
  bottom: 0;
}

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}

.rangeslider-vertical .rangeslider__tooltip {
  left: -55px;
  top: -15px;
}

.rangeslider-vertical .rangeslider__tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}

/**
* Rangeslider - Reverse
*/
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}

/**
* Rangeslider - Labels
*/
.rangeslider-vertical .rangeslider__label-list {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
}

.rangeslider-vertical .rangeslider__label-list .rangeslider__label {
  position: absolute;
}

.rangeslider-vertical .rangeslider__label-list .rangeslider__label::before {
  content: '';
  width: 10px;
  height: 2px;
  background: black;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.rangeslider__label-list .rangeslider__label {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
}

/*
 Fix for popup on disabled button
 https://codepen.io/jeffcarbs/pen/gLWBRj

 Doesnt work well. popup stay opened even after loosing focus
 */

.ui.button.disabled.pointer-events-enabled {
  //pointer-events: auto !important;
}

.ui.form .disabled.field,
.ui.disabled.input,
.ui.disabled.dropdown {
  opacity: 1 !important;
}

/* Fix modal position being to low */
.ui.modal {
  //top: 20%!important;
}

.react-tagsinput {
  background-color: #fff;
  // border: 1px solid #ccc;
  overflow: hidden;
  // padding-left: 5px;
  // padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #638421;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: ' ×';
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  // width: 80px;
}

.logo-customers {
  margin: 0 30px 40px 30px !important;
}

#stripes {
  height: 100vh;
  background: linear-gradient(150deg, #53f 15%, #05d5ff 70%, #a6ffcb 94%);
  clip-path: polygon(100% 0, 100% 70%, 0 99%, 0 0);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
}

#stripes :nth-child(1) {
  grid-area: 1 / 1 / span 4 / span 2;
  background-color: #53f;
  clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 100%);
}

#stripes :nth-child(2) {
  grid-area: 1 / 3 / span 3 / span 2;
  background-color: #4553ff;
  clip-path: polygon(0 0, 100% 0%, 100% 74%, 0% 100%);
  transform: translateY(-30px);
}

#stripes :nth-child(3) {
  grid-area: 1 / 5 / span 2 / span 2;
  background-color: #4f40ff;
  clip-path: polygon(0 0, 100% 0, 99% 5%, 0 70%);
}

#stripes :nth-child(4) {
  grid-area: 3 / 11 / span 3 / span 2;
  clip-path: polygon(0 23%, 100% 0%, 100% 77%, 0% 100%);
  background-color: #0dcfff;
}

#stripes :nth-child(5) {
  grid-area: 8 / 1 / span 5 / span 4;
  clip-path: polygon(0 23%, 100% 0%, 100% 80%, 0% 100%);
  transform: translateY(10px);
  background-color: #1fa2ff;
}

// Fix image size in blog
.ui.card .content img,
.ui.cards > .card .content img {
  display: block !important;
  max-width: 100% !important;
}

.divider-vertical {
  position: relative !important;
  padding: 0 !important;
}

.ui.basic.blue.label.background-transparent {
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}

.ui.basic.orange.label.background-transparent {
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}

.ui.basic.grey.label.background-transparent {
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}

//react-Pdf

.size-pdf {
  width: 100% !important;
  height: auto !important ;
}

// react-region-select

[data-wrapper="wrapper"]{
  caret-color: transparent;
  [data-dir] {
    caret-color: transparent;
  }
  &.zone-focus {
    border: 1px dashed #4553ff !important;
    [data-dir] {
      border: 1px solid #4553ff !important;
    }
    .zone-info{
      background-color: #4553ff;
      .zone-button-close {
        display: flex;
        background-color: transparent;
        &:hover{
          color: #7e87ff;
        }
      }
    }
  }
  &.zone-locked {
    border: none !important;
    background-color:rgba(117, 117, 117, 0.14);
    .zone-info{
      background-color: #dfdfdf;
      color: #646464;
    }
  }

  .zone-info{
    position: absolute;
    top: -1.7rem;
    left: 0.5rem;
    font-size: 0.8rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    background-color: #646464;
    border-radius: 1rem;
    .zone-label {
      color: white;
      padding: 0.1rem 0.5rem;
      font-weight: 700;
      display: flex;
      white-space: nowrap;
      margin: 0;
    }
    .zone-button-close {
      background-color: transparent;
      display: none;
      color: white;
      padding: 0.2rem;
      padding-right: 0.5rem;
      font-weight: 700;
      white-space: nowrap;
      border: none;
      margin: 0;
      margin-left: 0.5rem;
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        width: 1rem;
        height: 1rem;
      }
    }

  }
}

.content .header .btn-delete-zone{
  cursor: pointer;
  &:hover {
    color: #fcbf7c!important;
  }

}

.message > i.icon:before{
  display: block;
  margin-top: -0.2rem;
}
.ui.form .wrapped-field .field {
  margin-bottom: 0;
}

.ui.basic.orange.button.button-link-style {
  padding: 0.5rem 0 1rem 0;
  box-shadow: none!important;
  &:hover{
    box-shadow: none!important;
    color: #df9e56!important;
  }
  &:active{
    box-shadow: none!important;
    color: #df9e56!important;
  }
  &:focus{
    box-shadow: none!important;
    color: #df9e56!important;
  }
}


.ReactTable {
  min-height: 30rem;
}